.form-control {
  border-color: #d7d7d7;
}

.input-group-addon {
  color: $brown-dark;
}
.input-group .input-lg {
  font-size: $font-size-large;
  border-radius: $input-border-radius;
}
label {
  font-weight: normal;
}
.radio, .checkbox {
  margin: 0;
}
.table {
  // Cells
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        border-top: 2px solid $brand-primary;
      }
    }
    > tr.warning {
      > td {
        background-color: $brand-success;
      }
    }
  }
  // Bottom align for column headings
  > thead > tr > th {
    border-top: 2px solid $brand-primary;
    border-bottom: 2px solid $brand-primary;
  }

  > thead,
  > tbody,
  > tfoot {
    > tr.no-border {
      > th,
      > td {
        border-top:none;
      }
    }
  }
}

#tenantSearch td {
  vertical-align: middle;
}
